<template>
  <div>
    <section id="knowledge-base">
      <v-card
        flat
        class="mb-7"
      >
        <v-img
          src="@/assets/images/banners/teamBackground.png"
          height="250"
          position="right"
        />
        <v-card-text class="position-relative">
          <v-avatar
            size="60"
            color="white"
            class="avatar-center"
          >
            <v-img :src="$auth.user.picture"></v-img>
          </v-avatar>
          <!-- Title, Subtitle & Action Button -->
          <div class="d-flex justify-space-between flex-wrap pt-12">
            <div class="me-2 mb-2">
              <v-card-title class="pt-0 px-0">
                {{ $store.state.User.user.firstName }} {{ $store.state.User.user.lastName }}
              </v-card-title>
              <v-card-subtitle class="text-xs pa-0">
                {{ $auth.user.email }}
              </v-card-subtitle>
              <v-card-subtitle class="text-xs pa-0">
                {{ userProfile.ocp }}
              </v-card-subtitle>
            </div>
            <v-btn
              v-if="UserProfile.role[0] === 'PHARMACY_OWNER'"
              dark
              x-large
              color="success"
              @click="dialog2=true"
            >
              Add Team Members
              <v-icon
                dark
                right
              >
                {{ icons.mdiAccountMultiplePlusOutline }}
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="dialog2"
              persistent
              max-width="600px"
              :retain-focus="false"
            >
              >
              <v-card>
                <v-card-title>
                  <span class="headline">Add New User</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="addedUser.firstName"
                          required
                          :rules="[required]"
                          label="First Name"
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="addedUser.lastName"
                          :rules="[required]"
                          label="Last Name"
                          dense
                          persistent-hint
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="addedUser.email"
                          :rules="[required,emailValidator]"
                          label="Email"
                          dense
                        >
                          hide-details="auto"
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                      >
                        <v-text-field
                          v-model="addedUser.ocp"
                          :rules="[integerValidator]"
                          label="OCP #"
                          dense
                        >
                          hide-details="auto"
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                      >
                        <v-select
                          v-model="addedUser.role"
                          :rules="[required]"
                          :items="roles"
                          label="User Permissions"
                          dense
                          hide-details="auto"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-select
                          v-model="addedUser.pharmacyId"
                          :items="accNumbers"
                          label="Pharmacy*"
                          required
                          hide-details="auto"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="addedUser.password"
                          hide-details="auto"
                          :rules="[required,passwordValidator]"
                          dense
                          label="Password"
                          type="password"
                          hint=" At least 8 characters containing one lower case letter, upper case letter & number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="repeatePassword"
                          hide-details="auto"
                          :rules="[required,confirmedValidator(repeatePassword,addedUser.password)]"
                          dense
                          label="Repeat Password"
                          placeholder="Repeat password must match"
                          type="password"
                          hint=" At least 8 characters containing one lower case letter, upper case letter & number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    outlined
                    @click="close2"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    :loading="loadings.loading4"
                    :disabled="loadings.loading4"
                    color="success"
                    @click="addNewUser"
                  >
                    Save
                    <template #loader>
                      <span class="custom-loader">
                        <v-icon light>{{ icons.mdiCached }}</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-card-text>
      </v-card>
    </section>

    <v-row class="kb-search-content-info match-height">
      <v-col
        v-for="(pharmacy, index) in userPharmacies"
        :key="index"
        class="kb-search-content"
      >
        <v-card
          color="text-center cursor-pointer"
          flat
          class="mb-7"
        >
          <div class="kb-character-wrapper">
            <v-img
              max-width="150"
              contain
              class="mx-auto align-center justify-center"
              src="@/assets/images/icons/pharmacies.png"
            ></v-img>
          </div>
          <v-card-title class="justify-center pb-3">
            {{ pharmacy.name }} - {{ pharmacy.accNumber }}
          </v-card-title>
          <v-card-text>
            <h2>Team Members</h2>
          </v-card-text>
          <v-data-table
            :headers="headers"
            :items="userPharmacies[index].users"
          >
            <template #[`item.changes`]="{ item }">
              <v-icon
                medium
                class="me-3"
                @click="editItem(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
              <v-dialog
                v-model="DialogVisible"
                max-width="600px"
                :retain-focus="false"
              >
                >
                <v-card>
                  <v-card-title>
                    <span class="headline">User Profile</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="addedUser.firstName"
                            label="First Name"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="addedUser.lastName"
                            label="Last Name"
                            dense
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            v-model="addedUser.email"
                            hint="Please Contact PharmaGuide Support to update E-Mail"
                            disabled
                            label="Email"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                        >
                          <v-text-field
                            v-model="addedUser.ocp"
                            label="OCP #"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                        >
                          <v-select
                            v-model="addedUser.role[0]"
                            :items="roles"
                            label="User Permissions"
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      outlined
                      @click="DialogVisible = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      color="success"
                      @click="updateUser"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template #[`item.delete`]="{ item }">
              <v-icon
                medium
                class="me-3"
                @click="deleteItem(pharmacy, item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="Deleteconfirm"
      max-width="500px"
    >
      <v-alert type="warning">
        User Deleted Successfully
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="addTeamConfirm"
      max-width="500px"
    >
      <v-alert type="success">
        User Added Successfully
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="addTeamFail"
      max-width="500px"
    >
      <v-alert type="error">
        User Add Failed. Please Refresh and Try again.
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="updateTeamConfirm"
      max-width="500px"
    >
      <v-alert type="success">
        User Updated Successfully
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>

// import getUserOptions from "@auth0/auth0-spa-js";
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
    mdiDelete,
    mdiPencil,
    mdiCellphoneCog,
    mdiChevronLeft,
    mdiPhone,
    mdiFax,
    mdiEmailCheck,
    mdiMapMarker,
    mdiDeleteOutline,
    mdiPencilOutline,
    mdiAccountMultiplePlusOutline,
    mdiCached,
} from '@mdi/js'
import {
    required,
    emailValidator,
    passwordValidator,
    between,
    integerValidator,
    regexValidator,
    alphaValidator,
    urlValidator,
    confirmedValidator,
    lengthValidator,
    alphaDashValidator,
} from '@core/utils/validation'
import { userService, adminService } from '@/render/api/zarya'

export default {
    setup() {
        const requiredField = ref('')
        const numberBetween10to20 = ref('')
        const onlyConsistNumber = ref('')
        const matchRegularEx = ref('')
        const onlyAlphabeticCharacters = ref('')
        const specifiedLength = ref('')
        const password = ref('')
        const digits = ref('')
        const repeatePassword = ref('')
        const onlyAlphabeticNumbersDashesUnderscores = ref('')
        const email = ref('')
        const validURL = ref('')
        const form = ref(null)
        const confirmPassword = ref('')
        const validate = () => {
            form.value.validate()
            this.addNewUser()
        }

        const loadings = ref({
            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            loading5: false,
        })

        const triggerLoading = loadingIndex => {
            loadings.value[loadingIndex] = true

            setTimeout(() => {
                loadings.value[loadingIndex] = false
            }, 6000)
        }

        return {
            loadings,
            triggerLoading,
            requiredField,
            numberBetween10to20,
            onlyConsistNumber,
            matchRegularEx,
            onlyAlphabeticCharacters,
            specifiedLength,
            password,
            digits,
            repeatePassword,
            onlyAlphabeticNumbersDashesUnderscores,
            email,
            validURL,
            validate,
            form,
            confirmPassword,

            // validation
            required,
            emailValidator,
            passwordValidator,
            between,
            integerValidator,
            regexValidator,
            alphaValidator,
            urlValidator,
            confirmedValidator,
            lengthValidator,
            alphaDashValidator,
        }
    },
    computed: {
        ...mapGetters(['UserProfile']),
    },
    data() {
        return {
            icons: {
                mdiDelete,
                mdiPencil,
                mdiCellphoneCog,
                mdiChevronLeft,
                mdiPhone,
                mdiFax,
                mdiEmailCheck,
                mdiDeleteOutline,
                mdiMapMarker,
                mdiPencilOutline,
                mdiAccountMultiplePlusOutline,
                mdiCached,
            },
            userProfile: this.$store.getters.UserProfile,
            userPharmacies: '',
            UserPerPharmacy: '',
            headers: [
                {
                    text: 'First Name',
                    value: 'firstName',
                },
                {
                    text: 'Last Name',
                    value: 'lastName',
                },
                {
                    text: 'OCP#',
                    value: 'ocp',
                },
                {
                    text: 'E-mail Address',
                    value: 'email',
                },
                {
                    text: 'User Permissions',
                    value: 'role',
                },
                {
                    text: 'Points',
                    value: 'points',
                },
                {
                    text: 'Amend',
                    value: 'changes',
                    sortable: false,
                },
                {
                    text: 'Delete',
                    value: 'delete',
                    sortable: false,
                },
            ],
            roles: [
                {
                    text: 'Pharmacy Owner',
                    value: 'PHARMACY_OWNER',
                },
                {
                    text: 'Pharmacist',
                    value: 'PHARMACY_USER',
                },
                {
                    text: 'Relief',
                    value: 'PHARMACY_RELIEF',
                },
                {
                    text: 'Pharmacy Technician',
                    value: 'PHARMACY_TECH',
                },
                {
                    text: 'Accountant',
                    value: 'PHARMACY_ACCOUNTANT',
                },
            ],
            editedIndex: -1,
            dialog: false,
            dialogDelete: false,
            Deleteconfirm: false,
            addTeamConfirm: false,
            addTeamFail: false,
            updateTeamConfirm: false,
            DialogVisible: false,
            editedIndex: -1,
            editedItem: {},
            defaultItem: {},
            deleteItemPharmacy: {},
            deleteItemItem: {},
            accNumber: {},
            accredNum: {},
            addedUser: {
                firstName: '',
                lastName: '',
                ocp: '',
                email: '',
                role: '',
                accNumber: '',
                pharmacyId: '',
            },
            dialog2: false,
            accNumbers: {},
            snackbar: {
                show: false,
                text: '',
                color: '',
            },
        }
    },
    watch: {
        DialogVisible(val) {
            // eslint-disable-next-line no-unused-expressions
            val || this.close()
        },

        dialogDelete(val) {
            // eslint-disable-next-line no-unused-expressions
            val || this.closeDelete()
        },

        Deleteconfirm(val) {
            // eslint-disable-next-line no-unused-expressions
            val || this.closeDeleteConfirm()
        },
        addTeamConfirm(val) {
            val || this.closeAddTeamConfrim()
        },
        addTeamFail(val) {
            val || this.closeAddTeamFail()
        },
        updateTeamConfirm(val) {
            val || this.closeupdateTeamConfirm()
        },
        loader() {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => {
                this[l] = false
            }, 5000)

            this.loader = null
        },
    },
    beforeMount() {
        this.getUserInfo()
    },
    methods: {
        async getUserInfo() {
            // const id = this.$auth.user.sub.replace('auth0|', '')
            // const token = await this.$auth.getTokenSilently()
            const id = this.$auth.user.sub.replace('auth0|', '')
            const token = await this.$auth.getTokenSilently()

            this.userPharmacies = await userService
                .getUserPharmacies(
                    {
                        id,
                    },
                    token,
                )
                .then(response => response)

            this.UserPerPharmacy = await this.userPharmacies.map(pharmacy => pharmacy.users)

            // console.log(this.UserPerPharmacy)
            this.getPharmacyNames()
        },
        editItem(item) {
            this.addedUser = {
                firstName: item.firstName,
                lastName: item.lastName,
                ocp: item.ocp,
                email: item.email,
                role: item.role,
                id: item._id,
            }

            // this.dialog = true
            this.DialogVisible = true
        },
        close() {
            this.DialogVisible = false
            this.clearDialogInfo()
        },
        close2() {
            this.dialog2 = false
            this.clearDialogInfo()
        },
        clearDialogInfo() {
            this.addedUser = {
                firstName: '',
                lastName: '',
                ocp: '',
                email: '',
                role: '',
                id: '',
            }
        },
        async getPharmacyNames() {
            this.accNumbers = await this.userPharmacies.map(pharmacy => ({
                text: pharmacy.name,
                value: pharmacy._id,
                accNumber: pharmacy.accNumber,
            }))
            this.accNumber = await this.userPharmacies.map(pharmacyAcc => ({
                text: pharmacyAcc.name,
                value: pharmacyAcc.accNumber,
            }))
        },
        async addNewUser() {
            const accredNum = this.userPharmacies.find(pharmacy => pharmacy._id === this.addedUser.pharmacyId)
            this.addedUser.accNumber = accredNum.accNumber
            this.triggerLoading('loading4')
            const body = {
                pharmacy: this.addedUser.pharmacyId,
                user: {
                    email: this.addedUser.email,
                    password: this.addedUser.password,
                    firstName: this.addedUser.firstName,
                    lastName: this.addedUser.lastName,
                    ocp: this.addedUser.ocp,
                    accreditationNumbers: accredNum.accNumber,
                    role: this.addedUser.role,
                },
            }
            const token = await this.$auth.getTokenSilently()
            try {
                await adminService.addNewUser(body, token).then(() => {
                    this.addTeamConfirm = true
                    this.close2()
                })
            } catch (e) {
                console.log(e)
                this.addTeamFail = true
            }
            await this.getUserInfo()
        },
        async updateUser() {
            const token = await this.$auth.getTokenSilently()
            try {
                await userService
                    .amendUser(
                        this.addedUser.id,
                        token,
                        this.addedUser.role,
                        this.addedUser.firstName,
                        this.addedUser.lastName,
                        this.addedUser.ocp,
                    )
                    .then(response => response.data)
                    .then((this.DialogVisible = false))
                    .then((this.updateTeamConfirm = true))
            } catch (e) {
                console.log(e)
            }
            await this.getUserInfo()
        },

        // async deleteItem(pharmacy, item) {
        //   const token = await this.$auth.getTokenSilently()
        //   if (window.confirm('Are you sure you want to delete this User?')) {
        //     try {
        //       await userService.deleteUser(pharmacy, item, token)
        //     } catch (e) {
        //       console.log(e)
        //     }
        //     await this.getUserInfo()
        //   }
        // },
        async deleteItem(pharmacy, item) {
            this.dialogDelete = true
            const token = await this.$auth.getTokenSilently()
            this.deleteItemPharmacy = pharmacy
            this.deleteItemItem = item

            // console.log(this.deleteItemPharmacy, this.deleteItemItem)
        },

        // async deleteItem(pharmacy, item) {
        //   const token = await this.$auth.getTokenSilently()
        //   if (window.confirm('Are you sure you want to delete this User?')) {
        //     try {
        //       await userService.deleteUser(pharmacy, item, token)
        //     } catch (e) {
        //       console.log(e)
        //     }
        //     await this.getUserInfo()
        //   }
        // },
        async deleteItemConfirm() {
            const pharmacy = this.deleteItemPharmacy
            const item = this.deleteItemItem
            const token = await this.$auth.getTokenSilently()
            await userService.deleteUser(pharmacy, item, token)
            await this.getUserInfo()

            // console.log(this.stateID)
            this.closeDelete()
            this.Deleteconfirm = true
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem,
                }
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem,
                }
                this.editedIndex = -1
            })
        },
        closeDeleteConfirm() {
            this.Deleteconfirm = false

            // this.$nextTick(() => {
            //   this.editedItem = {
            //     ...this.defaultItem,
            //   }
            //   this.editedIndex = -1
            // })
        },
        closeAddTeamConfrim() {
            this.addTeamConfirm = false
        },
        closeAddTeamFail() {
            this.addTeamFail = false
            loadings.value[loadingIndex] = false
        },
        closeupdateTeamConfirm() {
            this.updateTeamConfirm = false
        },
        saveResponseSuccess() {
            this.snackbar.show = true
            this.snackbar.text = 'Successfully created new user'
            this.snackbar.color = 'green'
        },
        saveResponseReject() {
            this.snackbar.show = true
            this.snackbar.text = 'Failed to create new user'
            this.snackbar.color = 'red'
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';
// @import '@core/preset/preset/pages/Profile.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
